// Generated by Framer (d03ec02)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["LVQn2zFSs"];

const serializationHash = "framer-dddOE"

const variantClassNames = {LVQn2zFSs: "framer-v-1egpie"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, tap2, width, ...props}) => { return {...props, NB1_OYIMg: tap2 ?? props.NB1_OYIMg} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap2?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, NB1_OYIMg, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "LVQn2zFSs", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1aepe3e = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (NB1_OYIMg) {
const res = await NB1_OYIMg(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1egpie", className, classNames)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"LVQn2zFSs"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1aepe3e} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}}><motion.div className={"framer-t2x139"} layoutDependency={layoutDependency} layoutId={"g5R63Wefl"} style={{backgroundColor: "rgb(255, 255, 255)"}}/><motion.div className={"framer-qzfde4"} layoutDependency={layoutDependency} layoutId={"T6aemaFjQ"} style={{backgroundColor: "rgb(255, 255, 255)"}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-dddOE[data-border=\"true\"]::after, .framer-dddOE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-dddOE.framer-1paba3p, .framer-dddOE .framer-1paba3p { display: block; }", ".framer-dddOE.framer-1egpie { cursor: pointer; height: 36px; overflow: hidden; position: relative; width: 36px; will-change: var(--framer-will-change-override, transform); }", ".framer-dddOE .framer-t2x139 { flex: none; height: 2px; left: calc(50.00000000000002% - 16px / 2); overflow: visible; position: absolute; top: 14px; width: 16px; }", ".framer-dddOE .framer-qzfde4 { bottom: 14px; flex: none; height: 2px; left: 10px; overflow: visible; position: absolute; width: 12px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"NB1_OYIMg":"tap2"}
 * @framerImmutableVariables true
 */
const FramerNuKwSCZRN: React.ComponentType<Props> = withCSS(Component, css, "framer-dddOE") as typeof Component;
export default FramerNuKwSCZRN;

FramerNuKwSCZRN.displayName = "Atom/Nav Menu";

FramerNuKwSCZRN.defaultProps = {height: 36, width: 36};

addPropertyControls(FramerNuKwSCZRN, {NB1_OYIMg: {title: "Tap 2", type: ControlType.EventHandler}} as any)

addFonts(FramerNuKwSCZRN, [])